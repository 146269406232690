* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body,
select,
button {
  font-family: "Roboto", sans-serif;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  height: 100%;
}
.navbar {
  padding: 10px;
  display: flex;
  justify-content: space-between;
}
.Logo {
  display: flex;
  flex-direction: row;
  text-decoration: none;
}
li {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
}
.linkHeader {
  height: 30px;
  text-decoration: none;
}
.home {
  display: grid;
  grid-template-columns: 1fr 490px;
  grid-template-rows: 1fr;
  align-items: center;
  margin: 40px;
}
.HeadLine {
  text-transform: uppercase;
  font-size: 60px;
  font-weight: medium;
}
.line2 {
  margin: 15px;
}
.Btn {
  width: auto;
  height: 50px;
  margin-left: 15px;
  border-radius: 4px;
  padding: 16px 24px 16px 24px;
  cursor: pointer;
  color: white;
  background-color: #3a864b;
}
.Btn:hover {
  background-color: #bbe3c4;
  color: #000000;
}
.viewBtn {
  width: auto;
  height: 50px;
  margin-left: 680px;
  margin-top: 20px;
  border-radius: 4px;
  padding: 16px 24px 16px 24px;
  cursor: pointer;
  color: white;
  background-color: #3a864b;
}
.viewBtn:hover {
  background-color: #bbe3c4;
  color: #000000;
}
.btnLink {
  list-style: none;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: medium;
}
.btnLink1 {
  list-style: none;
  text-decoration: none;
  color: white;
  font-size: 18px;
  font-weight: medium;
}
.btnLink2 {
  display: none;
}
.icon {
  position: fixed;
  left: 97%;
  float: right;
}
h1 {
  font-weight: lighter;
  color: #3a864b;
}
.btnLink:hover {
  color: rgb(0, 0, 0);
}
.ImgWork {
  width: 250px;
  height: 150px;
}
.service {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.wordScrap {
  color: #3a864b;
  font-size: 60px;
  margin: 5px;
}
.aboutText {
  width: 700px;
  height: 220px;
  margin: 15px;
  word-spacing: 4px;
}
.steps {
  display: block;
}
.step {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  font-size: 20px;
}
carousel-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.carousel-wrapper {
  display: flex;
  width: 100%;
  position: relative;
}

.carousel-content-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.carousel-content {
  display: flex;
  transition: all 250ms linear;
  -ms-overflow-style: none; /* hide scrollbar in IE and Edge */
  scrollbar-width: none; /* hide scrollbar in Firefox */
}

/* hide scrollbar in webkit browser */
.carousel-content::-webkit-scrollbar,
.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-content > * {
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
}
.left-arrow,
.right-arrow {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background-color: white;
  border: 1px solid #ddd;
}

.left-arrow {
  left: 24px;
}

.right-arrow {
  right: 24px;
}
.PriceList {
  align-items: center;
  justify-content: center;
}
.Price {
  display: flex;
  flex-direction: row;
  margin-left: 100px;
  border: 1px solid black;
  background-color: aliceblue;
  height: 200px;
}
.PriceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 3px;
  margin-left: 10px;
}
.PriceContainer1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.priceCon {
  margin-left: 50px;
  margin-bottom: 20px;
  border: 1px solid green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
}
.priceCon1 {
  margin-left: 50px;
  margin-bottom: 20px;
  border: 1px solid green;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 320px;
  width: 300px;
  padding: 10px;
  border-radius: 10px;
}
.priceTag {
  background-color: #3a864b;
  padding: 10px;
  color: #ffffff;
  border: 3px solid none;
  border-radius: 10px;
  margin-top: 10px;
  position: sticky;
  font-size: 18px;
  font-weight: bolder;
}
.ProductName {
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  margin-top: 10px;
}
.ConactPage {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 30px;
  margin-top: 90px;
}
.ContactForm {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-top: 10px;
}
label {
  margin-left: 10px;
}

input,
textarea {
  margin: 10px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid black;
  box-shadow: wheat;
}
input:focus::placeholder {
  color: transparent;
}
textarea {
}
.conactImage {
  width: 600px;
  height: 400px;
  margin-left: 60px;
}
.Footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 30px;
  margin-left: 30px;
  color: rgb(198, 196, 196);
  font-weight: lighter;
}
.FooterMain {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-image: url("../public/bg-footer.png");
}
.footer-div {
  margin: 20px;
}
.img-1 {
  width: 400px;
}
.aboutPageT {
  width: 800px;
  height: auto;
  padding: 20px;
}
.headNumbers {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 8px;
  font-weight: bolder;
  color: #c6efcf;
}
.toggleBtn {
  display: none;
}
.navbarMobile {
  display: none;
}
@media only screen and (max-width: 600px) {
  .headNumbers {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 12px;
    padding: 6px;
    font-weight: bolder;
    color: #c6efcf;
  }
  .btnLink2 {
    display: block;
  }
  .toggleBtn {
    display: flex;
    margin-left: 150px;
  }
  .navbarMobile {
    display: block;
    background-color: #3a864b;
    padding: 25px;
    margin-top: 10px;
  }
  .navbar {
    display: none;
    background-color: #000000;
  }
  .linkHeader {
    color: white;
  }
  .header {
    display: block;
    flex-direction: column;
    padding: 0px;
  }
  li {
    padding: 8px;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #ffffff;
  }
  .aboutPageT {
    width: auto;
    height: auto;
    padding: 0px;
    margin-bottom: 30px;
  }
  .aboutPAgeImg {
    margin-bottom: 40px;
  }
  .wordScrap {
    color: "green";
    font-size: 50px;
    margin: 3px;
  }
  .btnLink1 {
    display: none;
  }
  .home {
    display: block;
  }
  .service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboutPageText {
    display: flex;
    flex-direction: column;
    margin: 0px;
    width: auto;
  }
  .img-1 {
    width: auto;
    height: auto;
    margin: 0px;
  }
  .icon {
    position: fixed;
    left: 0%;
    display: none;
  }
  .HeadLine {
    font-size: 55px;
  }
  .step {
    margin: 20px;
  }
  .works {
    margin-top: 320px;
  }
  .aboutText1 {
    width: auto;
    margin-top: 420px;
    height: 300px;
  }
  .aboutText {
    width: auto;
    height: auto;
    margin: 10px;
  }
  .aboutImg {
    margin-top: 190px;
    width: auto;
    height: auto;
  }
  .PriceContainer {
    flex-direction: column;
    margin: 0px;
    margin-left: 0px;
  }
  .PriceContainer1 {
    display: grid;
    grid-template-columns: 1fr;
  }
  .priceCon {
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 270px;
    padding: 5px;
    border-radius: 10px;
  }
  .ConactPage {
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-top: 90px;
  }
  .conactImage {
    width: 300px;
    height: 200px;
    margin-left: 30px;
    display: none;
  }
  .Footer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-left: 20px;
    color: rgb(198, 196, 196);
    font-weight: lighter;
  }
  .FooterMain {
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-image: url("../public/bg-footer.png");
  }
  .viewBtn {
    margin-left: 150px;
  }
}
